/**
 * 配置编译环境和线上环境之间的切换
 *
 * baseUrl: 域名地址
 * routerMode: 路由模式
 *
 */
let protocol = 'https://';
let apiUrl = ''
let baseUrl = 'dast.133.cn';
let assetUrl = protocol + 'dast.133.cn'
if (process.env.NODE_ENV == 'development') {//本地开发
    apiUrl = 'http://delay-api.data.com:8080';
} if (process.env.NODE_ENV == 'test') {//测试环境
    apiUrl = 'http://delay-api.data.com:8080';
} if (process.env.NODE_ENV == 'production') {//生产环境
    apiUrl = protocol + 'backend.ddflight.com';
}
export {
    apiUrl,
    baseUrl,
    protocol,
    assetUrl
}
