<template>
    <div>
        <div class="main m-width m-auto">
            <div class="flex flex-yc flex-xc">
                <img :src="assetUrl+'/assets/img/404.png'" alt="">
            </div>
        </div>
    </div>
</template>
<script>
    import { assetUrl } from '@/config/env'
    export default {
        components: {
            // Header: () => import("./../common/header"),

        },
        data() {
            return {
                assetUrl: assetUrl
            };
        },
        methods: {

        },

    };
</script>